import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Hero from "../components/home/hero";
import ContactForm from "../components/home/contact";
import Features from "../components/home/features";

function Page() {
  return (
    <Layout>
      <SEO
        title="Podcast Monitoring, Optimization and Performance"
        reverseTitle={false}
      />

      <Hero />

      <Features />

      <ContactForm />
    </Layout>
  );
}

export default Page;
