import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://app.pinwheel.fm";

const loginURL = `${baseURL}/login`;

function Header() {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <nav>
      <div className="container mx-auto px-6 py-2 flex justify-between items-center">
        <Link to="/" className="font-bold text-2xl lg:text-4xl">
          {site.siteMetadata.title}
        </Link>
        <div className="block lg:hidden">
          <button className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none">
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div className="hidden lg:block">
          <ul className="inline-flex">
            <li>
              <Link
                to="/"
                activeClassName="font-bold"
                className="px-4 hover:text-gray-800"
                title="Home"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/validator"
                activeClassName="font-bold"
                className="px-4 hover:text-gray-800"
                title="Podcast RSS Feed Validator"
              >
                Validator
              </Link>
            </li>
            <li>
              <Link
                to="/features"
                activeClassName="font-bold"
                className="px-4 hover:text-gray-800"
                title="Features"
              >
                Features
              </Link>
            </li>
            <li>
              <a
                href={loginURL}
                className="bg-transparent hover:bg-amaranth text-amaranth font-semibold hover:text-white py-2 px-4 border border-amaranth hover:border-transparent rounded"
                title="Login"
              >
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
