import React from "react";

import background from "../../images/listener2.jpg";

function Form() {
  return (
    <div
      className="py-20"
      style={{
        backgroundImage: `linear-gradient(90deg, rgba(147, 32, 99, 1) 40%, rgba(102, 126, 234, 0.2) 100%), url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold mb-2 text-white">
          Be Everywhere Your Podcast Listeners Are
        </h2>
        <h3 className="text-2xl mb-8 text-gray-100">
          Monitoring, Optimization and Performance tools to{" "}
          <strong>boost your Podcast</strong>.
        </h3>

        {/* <button className="bg-white font-bold rounded-md py-4 px-8 shadow-lg uppercase tracking-wider">
          Pre Order
        </button> */}
      </div>
    </div>
  );
}

export default Form;
