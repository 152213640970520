import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

function SEO({ description, lang, meta, keywords, title, reverseTitle }) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          keywords
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  keywords.push(site.siteMetadata.keywords);

  const titleTemplate =
    reverseTitle === false
      ? `${site.siteMetadata.title} | %s`
      : `%s | ${site.siteMetadata.title}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: `noindex, nofollow`,
        },
        {
          name: `keywords`,
          content: keywords.join(`, `),
        },
      ].concat(meta)}
      title={title}
      titleTemplate={titleTemplate}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
  children: null,
  reverseTitle: true,
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.array,
  ]),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
  reverseTitle: PropTypes.bool,
};

export default SEO;
